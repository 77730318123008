/**
 * Modify numbers over 9999 to a string with K suffix.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';
import { tenK } from '@shared/utilities/number.utilities';

@Pipe({
  name: 'tenK',
})
export class TenThousands implements PipeTransform {
  public transform(value: number): string | null {
    return tenK(value);
  }
}
