import { switchMap, tap, distinctUntilChanged, map, takeUntil, startWith } from 'rxjs/operators';
import { from, Subject, timer, fromEvent, merge, animationFrameScheduler, Observable } from 'rxjs';

import {
  Directive,
  Input,
  ElementRef,
  DoCheck,
  AfterViewInit,
  HostBinding,
  Injectable,
  OnDestroy,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Injectable({
  providedIn: 'root',
})
export class TextareaAutoSizeService {
  private ctx = document.createElement('canvas').getContext('2d');

  private map = new Map<HTMLTextAreaElement, CSSStyleDeclaration | null>();

  register(input: HTMLTextAreaElement): void {
    this.map.set(input, null);
  }

  unregister(input: HTMLTextAreaElement): void {
    this.map.delete(input);
  }

  requestHeight(input: HTMLTextAreaElement, text: string, minRows: number): Observable<number> {
    return timer(0, animationFrameScheduler).pipe(
      map(() => {
        let style = this.map.get(input);

        if (!style) {
          style = window.getComputedStyle(input);
          this.map.set(input, style);
        }

        // twice because bug with font weight
        this.ctx.font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
        this.ctx.font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;

        let lineHeight = parseFloat(style.lineHeight) || 1.2;

        if (!style.lineHeight.endsWith('px')) {
          lineHeight = (lineHeight * parseFloat(style.fontSize)) / (style.lineHeight.endsWith('px') ? 100 : 1);
        }

        const width = parseFloat(style.width);
        const metrics: TextMetrics = this.ctx.measureText(text);
        const newLines = text?.split(/\r\n|\r|\n/).length || 1;

        return Math.max(minRows, Math.ceil(metrics.width / width) + newLines - 1) * lineHeight;
      }),
    );
  }
}

@Directive({
  selector: 'textarea[autoSize]',
  providers: [LifecycleHooks],
})
export class TextareaAutoSize implements DoCheck, AfterViewInit, OnDestroy {
  private input: HTMLTextAreaElement = this.el.nativeElement;

  private check$ = new Subject<string>();

  @Input('autoSize')
  enabled?: boolean;

  @Input()
  autoSizeMinRows: number = 1;

  @HostBinding('style.overflow')
  readonly overflow = 'hidden';

  readonly fontsReady$ = from((document as any).fonts?.ready || Promise.resolve());

  private previousValue?: string;

  constructor(
    private el: ElementRef<HTMLTextAreaElement>,
    private lh: LifecycleHooks,
    private ts: TextareaAutoSizeService,
  ) {
    this.ts.register(this.input);
  }

  ngDoCheck(): void {
    const newValue = this.getValue();

    if (newValue !== this.previousValue) {
      this.check$.next(newValue);
    }
  }

  ngAfterViewInit(): void {
    const parent = this.input?.closest('mat-form-field');

    if (parent instanceof HTMLElement) {
      parent.style.height = 'initial';
    }

    merge(this.fontsReady$, timer(1000).pipe(takeUntil(this.fontsReady$)))
      .pipe(
        switchMap(() =>
          merge(
            fromEvent(this.input, 'input').pipe(
              map(() => this.getValue()),
              startWith(this.getValue()),
            ),
            this.check$,
          ),
        ),
        tap((text) => (this.previousValue = text)),
        distinctUntilChanged(),
        switchMap((text) => this.ts.requestHeight(this.input, text, this.autoSizeMinRows)),
        distinctUntilChanged(),
        takeUntil(this.lh.destroy),
      )
      .subscribe((height: number) => (this.input.style.height = `${height + 2}px`));
  }

  ngOnDestroy(): void {
    this.ts.unregister(this.input);
  }

  private getValue(): string {
    return this.input.value || this.input.placeholder || '';
  }
}
