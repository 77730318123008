/**
 * Injects CSS styles into the document head.
 *
 * @unstable
 */

import { Directive, OnDestroy, OnChanges, Input, ElementRef, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[injectStyles]',
})
export class InjectStyles implements OnDestroy, OnChanges {
  public stylesElement: any = null;

  @Input() injectStyles: string = '';

  constructor(readonly elRef: ElementRef) {}

  ngOnDestroy(): void {
    if (this.stylesElement) {
      this.stylesElement.innerHTML = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['injectStyles'] && changes['injectStyles'].currentValue != null) {
      const styles = this.injectStyles;

      console.log('Injecting CSS styles');

      if (!this.stylesElement) {
        this.stylesElement = document.createElement('style');

        this.stylesElement.type = 'text/css';

        document.head.appendChild(this.stylesElement);
      }

      this.stylesElement.innerHTML = styles;
    }
  }
}
