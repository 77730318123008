import { ReplaySubject, merge, race, timer, Subject } from 'rxjs';
import { takeUntil, startWith, switchMap, mapTo, shareReplay, filter, tap } from 'rxjs/operators';

import { Directive, ContentChildren, QueryList, HostListener, AfterContentInit, ElementRef } from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[focusRetain]',
})
export class FocusRetain {
  readonly blur$ = new Subject<UIEvent>();

  readonly focus$ = new Subject<UIEvent>();

  constructor(readonly el: ElementRef<HTMLInputElement>) {}

  @HostListener('blur', ['$event'])
  onBlur(event: UIEvent) {
    this.blur$.next(event);
  }

  @HostListener('focus', ['$event'])
  onFocus(event: UIEvent) {
    this.focus$.next(event);
  }
}

@Directive({
  selector: '[focusControl]',
  providers: [LifecycleHooks],
})
export class FocusControl implements AfterContentInit {
  @ContentChildren(FocusRetain, { descendants: true })
  retainers: QueryList<FocusRetain>;

  private retainers$ = new ReplaySubject<FocusRetain[]>(1);

  private activeRetainer$ = this.retainers$.pipe(
    switchMap((retainers) =>
      merge(
        ...retainers.map((retainer) =>
          retainer.focus$.pipe(
            mapTo(retainer),
            startWith(retainer.el.nativeElement === document.activeElement ? retainer : null),
            filter((item) => !!item),
          ),
        ),
      ),
    ),
    shareReplay(1),
  );

  constructor(private lh: LifecycleHooks) {}

  ngAfterContentInit(): void {
    this.retainers.changes
      .pipe(startWith(this.retainers.toArray()), takeUntil(this.lh.destroy))
      .subscribe((retainers: FocusRetain[]) => {
        this.retainers$.next(retainers);
      });

    this.activeRetainer$
      .pipe(
        switchMap((active) =>
          race(
            active.blur$.pipe(
              switchMap(() =>
                race(
                  this.activeRetainer$.pipe(filter((retainer) => retainer !== active)),
                  timer(10).pipe(tap(() => active.el.nativeElement.focus())),
                ),
              ),
            ),
            timer(500),
          ),
        ),
        takeUntil(this.lh.destroy),
      )
      .subscribe();
  }
}
