import { Directive, OnDestroy, ElementRef, HostBinding, Attribute, OnInit } from '@angular/core';

@Directive({
  selector: '[stickyElement]',
})
export class StickyElement implements OnDestroy, OnInit {
  @HostBinding('style.border-top')
  readonly borderTop = '1px solid transparent';

  @HostBinding('style.position')
  readonly position = 'sticky';

  @HostBinding('style.top.px')
  readonly top: number = -1;

  readonly observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle(this.stickyClass, e.intersectionRatio < 1),
    { threshold: [1] },
  );

  constructor(
    @Attribute('stickyElement')
    readonly stickyClass: string = 'is-sticky',
    readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
