/**
 * Blurs the input when enter is pressed.
 *
 * @unstable
 */

import { Directive, HostListener, ElementRef } from '@angular/core';

/**
 * Automatically blurs an input if enter is pressed.
 */
@Directive({
  selector: 'input[enterBlur], textarea[enterBlur]',
})
export class EnterBlur {
  @HostListener('keyup.enter', ['$event']) onKeyUp(): void {
    if (this.elRef) {
      this.elRef.nativeElement.blur();
    }
  }

  constructor(private elRef: ElementRef<HTMLElement>) {}
}
