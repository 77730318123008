/**
 * Pseudo style is ngStyle like directive for pseudo styles.
 *
 * @howToUse
 * ```
 * <some-element [pseudoStyle]="{'font-style': styleExp}">...</some-element>
 *
 * <some-element [pseudoStyle.hover]="{'max-width.px': widthExp}">...</some-element>
 *
 * <some-element [pseudoStyle]="objExp">...</some-element>
 * ```
 *
 * @description
 *
 * The styles are updated according to the value of the expression evaluation:
 * - keys are style names with an optional `.<unit>` suffix (ie 'top.px', 'font-style.em'),
 * - values are the values assigned to those properties (expressed in the given unit).
 *
 * You can give different styles for different states.
 * - States supported are: 'hover', 'focus', 'active'
 * - Styles are cascade in this order: 'active', 'focus', 'hover' & normal styles
 *
 * @unstable
 */

import {
  Directive,
  DoCheck,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  KeyValueChanges,
  KeyValueDiffer,
  KeyValueDiffers,
  ChangeDetectorRef,
} from '@angular/core';

type PseudoStyleStates = 'normal' | 'hover' | 'focus' | 'active';

@Directive({
  selector: '[pseudoStyle], [pseudoStyle.hover], [pseudoStyle.focus], [pseudoStyle.active]',
})
export class PseudoStyle implements DoCheck {
  private isActive: boolean = false;
  private isFocused: boolean = false;
  private isHovering: boolean = false;

  private differ: KeyValueDiffer<string, string | number>;

  private stylesMap: Map<PseudoStyleStates, { [key: string]: string }>;

  @Input('pseudoStyle')
  set normalStyles(v: { [key: string]: string }) {
    this.setStyles('normal', v);
  }

  @Input('pseudoStyle.hover')
  set hoverStyles(v: { [key: string]: string }) {
    this.setStyles('hover', v);
  }

  @Input('pseudoStyle.focus')
  set focusStyles(v: { [key: string]: string }) {
    this.setStyles('hover', v);
  }

  @Input('pseudoStyle.active')
  set activeStyles(v: { [key: string]: string }) {
    this.setStyles('active', v);
  }

  @HostListener('blur', ['$event'])
  @HostListener('focus', ['$event'])
  handleFocus(event: Event) {
    this.isFocused = event.type === 'focus';
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  handleHover(event: Event) {
    this.isHovering = event.type === 'mouseenter';
  }

  @HostListener('mousedown', ['$event'])
  handleActive(event: Event) {
    this.isActive = event.type === 'mousedown';

    if (this.isActive) {
      // TODO: What is the support for event listener options?
      // If possible, set the 'once' to true.
      const mouseUpListener = () => {
        this.isActive = false;

        document.removeEventListener('mouseup', mouseUpListener);

        this.cdRef.markForCheck();
      };

      document.addEventListener('mouseup', mouseUpListener);
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private elRef: ElementRef,
    private differs: KeyValueDiffers,
    private renderer: Renderer2,
  ) {}

  ngDoCheck() {
    if (this.differ) {
      const combinedStyle = this.cascadeStyles();

      const changes = this.differ.diff(combinedStyle);

      if (changes) {
        this.applyChanges(changes);
      }
    }
  }

  private setStyle(nameAndUnit: string, value: string | number | null | undefined): void {
    const [name, unit] = nameAndUnit.split('.');

    value = value != null && unit ? `${value}${unit}` : value;

    if (value != null) {
      this.renderer.setStyle(this.elRef.nativeElement, name, value as string);
    } else {
      this.renderer.removeStyle(this.elRef.nativeElement, name);
    }
  }

  private setStyles(target: PseudoStyleStates, styles: { [key: string]: string }) {
    if (!this.stylesMap) {
      this.stylesMap = new Map<PseudoStyleStates, { [key: string]: string }>();
    }

    if (!this.differ) {
      this.differ = this.differs.find({}).create();
    }

    this.stylesMap.set(target, styles);
  }

  private applyChanges(changes: KeyValueChanges<string, string | number>): void {
    changes.forEachRemovedItem((record) => this.setStyle(record.key, null));
    changes.forEachAddedItem((record) => this.setStyle(record.key, record.currentValue));
    changes.forEachChangedItem((record) => this.setStyle(record.key, record.currentValue));
  }

  private cascadeStyles(): { [key: string]: string } {
    let styles: { [key: string]: string } = {};

    const normalStyles = this.stylesMap.get('normal') || {};

    styles = normalStyles;

    if (this.isHovering) {
      const hoverStyles = this.stylesMap.get('hover') || {};

      styles = { ...styles, ...hoverStyles };
    }

    if (this.isFocused) {
      const focusStyles = this.stylesMap.get('focus') || {};

      styles = { ...styles, ...focusStyles };
    }

    if (this.isActive) {
      const activeStyles = this.stylesMap.get('active') || {};

      styles = { ...styles, ...activeStyles };
    }

    return styles;
  }
}
