import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ZefThemePalette } from '@shared/models/color.model';
import { ScaleAnimation } from '@shared/animations/scale.anim';

@Component({
  selector: 'zef-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  animations: [ScaleAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearch {
  @Input()
  value?: string;

  @Input()
  placeholder?: string;

  @Input()
  autoFocus?: boolean;

  @Input()
  space?: boolean;

  @Input()
  ghost?: boolean;

  @Input()
  elevated?: boolean;

  @Input()
  disabled?: boolean;

  @Input()
  big?: boolean;

  @Input()
  icon = 'action_find';

  @Input()
  iconColor: ZefThemePalette = 'ink';

  @Input()
  loading?: boolean;

  @Output()
  readonly valueChange = new EventEmitter<string | null>();

  @Output()
  readonly inputFocus = new EventEmitter<void>();

  @Output()
  readonly inputBlur = new EventEmitter<void>();

  @Output()
  readonly close = new EventEmitter<void>();

  @ViewChild('input')
  input?: MatInput;

  constructor(readonly el: ElementRef<HTMLElement>) {}

  onCloseClick(): void {
    if (this.input) {
      this.input.value = '';
    }
    this.valueChange.emit(null);
    this.close.emit();
  }

  focus(): void {
    this.input?.focus();
  }
}
