/**
 * Adds order number in front of the text.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderNumber',
})
export class OrderNumber implements PipeTransform {
  constructor() {}

  public transform(index: number, ...args: any[]): string {
    const [addition = 1, suffix] = args;

    let value = Number.isInteger(index) && Number.isInteger(addition) ? index + addition : index;

    value = value && suffix ? value + suffix : value;

    return value;
  }
}
