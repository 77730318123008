import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, shareReplay, take, takeUntil, tap } from 'rxjs/operators';

import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { NavigationStart, Router } from '@angular/router';

@Directive()
export abstract class BaseOverlay<T = any> implements OnChanges, OnDestroy {
  private dialogRef?: DialogRef<TemplateRef<any>>;

  private destroy$ = new Subject<void>();

  private isOpen = new BehaviorSubject<boolean>(false);

  readonly isOpen$ = this.isOpen.pipe(distinctUntilChanged(), shareReplay({ refCount: true, bufferSize: 1 }));

  @ViewChild('content', { read: TemplateRef, static: true })
  _content?: TemplateRef<any>;

  get content(): TemplateRef<any> {
    return this._content;
  }

  get dialogReference(): DialogRef<TemplateRef<any>> {
    return this.dialogRef;
  }

  @Input()
  open?: boolean;

  @Input()
  waitClose?: Observable<boolean>;

  @Output()
  readonly close = new EventEmitter<T>();

  @Output()
  readonly afterClosed = new EventEmitter<void>();

  confirmClose?: Observable<boolean>;

  protected abstract config: DialogConfig;

  private closing?: boolean;

  protected constructor(
    private dialog: Dialog,
    private vc: ViewContainerRef,
    router: Router,
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.closeDialog();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open) {
      this.toggle(this.open);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.hide();
  }

  @HostListener('document:keydown.escape')
  onEscape(): void {
    if (this.isOpen.getValue()) {
      this.closeDialog();
    }
  }

  protected show(): DialogRef<TemplateRef<any>> {
    if (this.content) {
      this.dialogRef = this.dialog.open(this.content, {
        ...this.config,
        viewContainerRef: this.config.viewContainerRef || this.vc,
      });

      this.isOpen.next(true);

      this.dialogRef.backdropClick.subscribe(() => {
        if (this.isOpen.getValue()) {
          this.closeDialog();
        }
      });

      this.dialogRef.closed.subscribe(() => {
        this.afterClosed.emit();
      });
    }

    return this.dialogRef;
  }

  protected hide(): void {
    this.isOpen.next(false);
  }

  protected closeDialog(value?: T): void {
    if (this.closing || !this.isOpen.getValue()) {
      return;
    }

    this.closing = true;

    const close$ = this.confirmClose || this.waitClose || of(true);

    close$
      .pipe(
        tap(() => (this.closing = false)),
        filter((close) => !!close),
        take(1),
        finalize(() => (this.closing = false)),
      )
      .subscribe(() => {
        this.hide();
        this.close.emit(value);
      });
  }

  toggle(show?: boolean): DialogRef<TemplateRef<any>> | void {
    show = show === undefined ? !this.isOpen.getValue() : !!show;

    if (show !== this.isOpen.getValue()) {
      return show ? this.show() : this.closeDialog();
    }
  }

  onAnimationDone(event: AnimationEvent): void {
    if (event.toState === 'void' && !this.isOpen.getValue()) {
      this.dialogRef?.close();
    }
  }

  resetClosing(): void {
    this.confirmClose = undefined;
    this.closing = false;
  }
}
