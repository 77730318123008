/**
 * Clamps number to be between min & max values.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clampNumber',
})
export class ClampNumber implements PipeTransform {
  constructor() {}

  public transform(number: number, min: number, max: number): number {
    return Math.min(Math.max(number, min), max);
  }
}
