import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { isURL } from 'class-validator';

@Directive({
  selector: 'input[type="url"]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true }],
})
export class UrlValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();

    return !value || isURL(value, { require_tld: true }) ? null : { url: { value } };
  }
}
