import { AreaMode, TopLeft } from '@shared/models/utility.model';

export function toStylePercent(percent: number): string {
  return `${(percent ?? 0) * 100}%`;
}

export function fixRounding(nr: number): number {
  return Math.round(nr * 1e6) / 1e6;
}

export function clamp(target: number, min: number = 0, max: number = 1): number {
  return Math.min(Math.max(target, min), max);
}

export function closestStep(nr: number, step: number): number {
  const quotient = Math.floor(nr / step);
  const first = fixRounding(step * quotient);
  const second = fixRounding(step * (quotient + (nr * step > 0 ? 1 : -1)));

  return Math.abs(nr - first) < Math.abs(nr - second) ? first : second;
}

export function clampPercentToStep(target: number, step: number, min: number, max: number): number {
  const size = max - min;

  return clamp(closestStep(size * target, step) / size);
}

export function clampEventToRect(event: MouseEvent, rect: ClientRect): TopLeft {
  return {
    left: clamp((clamp(event.clientX, rect.left, rect.left + rect.width) - rect.left) / rect.width),
    top: clamp((clamp(event.clientY, rect.top, rect.top + rect.height) - rect.top) / rect.height),
  };
}

export function getEventDistanceToRect(event: MouseEvent, rect: ClientRect): number {
  const x = Math.min(Math.abs(rect.left - event.clientX), Math.abs(rect.left + rect.width - event.clientX));
  const y = Math.min(Math.abs(rect.top - event.clientY), Math.abs(rect.top + rect.height - event.clientY));

  return Math.sqrt(x * x + y * y);
}

export function clampToAreaMode(position: TopLeft, mode: AreaMode): TopLeft {
  if (mode === AreaMode.XX) {
    return {
      top: 0.5,
      left: position.left,
    };
  } else if (mode === AreaMode.YY) {
    return {
      top: position.top,
      left: 0.5,
    };
  } else {
    return position;
  }
}

export function tenK(value: number): string {
  return +value < 10000 ? `${value}` : `${(+value / 1000).toFixed(1)}k`.replace('.0', '');
}
