/**
 * Sends an event when clicked outside of the element.
 *
 * @unstable
 */

import { fromEvent, Subscription, merge } from 'rxjs';

import { Directive, Output, EventEmitter, ElementRef, Input, NgZone } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutside {
  static listener = merge(
    fromEvent<MouseEvent>(document, 'click', { passive: true, capture: true }),
    fromEvent<MouseEvent>(document, 'touchend', { passive: true, capture: true }),
  );

  private sub?: Subscription;

  @Input() set clickOutsideActive(active: boolean) {
    this.nz.runOutsideAngular(() => {
      if (active && !this.sub) {
        this.sub = ClickOutside.listener.subscribe((event) => {
          if (!this.elRef.nativeElement.contains(event.target as HTMLElement)) {
            this.nz.run(() => this.clickOutside.emit(event));
          }
        });
      } else if (!active && this.sub) {
        this.sub.unsubscribe();
        this.sub = undefined;
      }
    });
  }

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  constructor(
    readonly elRef: ElementRef<HTMLElement>,
    readonly nz: NgZone,
  ) {}
}
