<div *cdkPortal class="inline-dialog-wrapper">
  <ng-container *ngTemplateOutlet="customDialog?.tr || inlineDialog"></ng-container>

  <ng-template #inlineDialog>
    <div class="zef-visual inline-dialog">
      <button mat-icon-button class="dialog-close" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>

      <div class="zef-text-h3 dialog-title">
        <ng-container *ngTemplateOutlet="dialogTitle.tr; context: ctx"></ng-container>
      </div>

      <div class="zef-text-p1 dialog-content">
        <ng-container *ngTemplateOutlet="dialogContent.tr; context: ctx"></ng-container>
      </div>

      <button
        *ngIf="dialogAction"
        mat-raised-button
        class="dialog-action zef-stretch-button"
        [color]="color"
        [loading]="loading"
        [disabled]="disableAction"
        (click)="onConfirmClick()"
      >
        <ng-container *ngTemplateOutlet="dialogAction.tr; context: ctx"></ng-container>
      </button>
    </div>
  </ng-template>
</div>
