/**
 * Auto grow directive for single line text inputs
 * Sets the width to ch (character unit)
 */

import { Directive, HostListener, ElementRef, OnChanges, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: 'input[autoGrow]',
})
export class AutoGrow implements OnChanges {
  @Input() ngModel;

  @Input() autoGrowExtraPad: number = 0;

  @HostListener('keydown', ['$event'])
  onChange(event: KeyboardEvent) {
    const elem = event.target as HTMLInputElement;
    this.setWidth(elem);
  }

  @HostListener('ngModelChange')
  onValueChange() {
    const elem = this.elem.nativeElement as HTMLInputElement;
    this.setWidth(elem);
  }

  constructor(readonly elem: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ngModel'] && changes['ngModel'].firstChange) {
      const elem = this.elem.nativeElement as HTMLInputElement;
      const initialText = changes['ngModel'].currentValue;
      this.setWidth(elem, initialText);
    }
  }

  private setWidth(elem: HTMLInputElement, value?: string) {
    const textLength = value ? value.length : elem.value.length;
    elem.style.width = `${textLength + this.autoGrowExtraPad}ch`;
  }
}
