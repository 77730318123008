import { fromEvent, mapTo, merge, Observable, of, tap } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { Directionality } from '@angular/cdk/bidi';
import { ContentObserver } from '@angular/cdk/observers';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Optional, Output } from '@angular/core';

import { NgScrollbar } from 'ngx-scrollbar';

import { tweenTo } from '@shared/operators/tween-to.operator';
import { getPositionOfChildElement } from '@shared/utilities/dom.utilities';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[zefScrollable]',
  providers: [LifecycleHooks],
})
export class NgScrollScrollable extends CdkScrollable implements OnInit {
  @Output()
  readonly canScrollY = new EventEmitter<boolean>();

  constructor(
    private co: ContentObserver,
    private lh: LifecycleHooks,
    elementRef: ElementRef<HTMLElement>,
    scrollDispatcher: ScrollDispatcher,
    ngZone: NgZone,
    @Optional() readonly ngs?: NgScrollbar,
    @Optional() dir?: Directionality,
  ) {
    super(elementRef, scrollDispatcher, ngZone, dir);
  }

  ngOnInit() {
    if (this.ngs) {
      this.elementRef = this.ngs.viewport?.viewPort;
    }

    merge(fromEvent(window, 'resize', { passive: true }), this.co.observe(this.elementRef.nativeElement))
      .pipe(
        startWith(void 0),
        map(() => {
          const el = this.elementRef.nativeElement;

          return el.scrollHeight > el.clientHeight;
        }),
        distinctUntilChanged(),
      )
      .subscribe((canScrollY) => this.canScrollY.emit(canScrollY));

    super.ngOnInit();
  }

  scrollToElement(
    element?: HTMLElement | ElementRef<HTMLElement>,
    options?: { duration?: number; offset?: number },
  ): Observable<void> {
    const targetElement = element instanceof HTMLElement ? element : element?.nativeElement;
    options ??= {};
    options.duration ??= 0;
    options.offset ??= 0;

    const currentTop = this.elementRef.nativeElement.scrollTop;
    const top = element ? getPositionOfChildElement(targetElement, this.elementRef.nativeElement) - options.offset : 0;

    if (currentTop !== top && !options.duration) {
      this.scrollTo({ top });
    }

    if (currentTop === top || !options.duration) {
      return of(void 0);
    }

    return of([currentTop, top]).pipe(
      tweenTo(500, (nextTop) => this.scrollTo({ top: nextTop })),
      delay(200),
      tap(() => {
        const nextTop = targetElement
          ? getPositionOfChildElement(targetElement, this.elementRef.nativeElement) - options.offset
          : 0;

        if (nextTop !== top) {
          this.scrollTo({ top: nextTop });
        }
      }),
      mapTo(void 0),
    );
  }
}
