import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Directive, NgModule } from '@angular/core';
import { _MatMenuBase, MAT_MENU_DEFAULT_OPTIONS, MAT_MENU_PANEL, matMenuAnimations, MatMenuItem, _MatMenuTriggerBase, _MatMenuContentBase, MAT_MENU_CONTENT, MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/menu';
export { MAT_MENU_CONTENT as MAT_LEGACY_MENU_CONTENT, MAT_MENU_DEFAULT_OPTIONS as MAT_LEGACY_MENU_DEFAULT_OPTIONS, MAT_MENU_PANEL as MAT_LEGACY_MENU_PANEL, MAT_MENU_SCROLL_STRATEGY as MAT_LEGACY_MENU_SCROLL_STRATEGY, fadeInItems as fadeInLegacyItems, matMenuAnimations as matLegacyMenuAnimations, transformMenu as transformLegacyMenu } from '@angular/material/menu';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/core';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

/**
 * @docs-public MatMenu
 * @deprecated Use `MatMenu` from `@angular/material/menu` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
const _c0 = ["*"];
function MatLegacyMenu_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵlistener("keydown", function MatLegacyMenu_ng_template_0_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._handleKeydown($event));
    })("click", function MatLegacyMenu_ng_template_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.closed.emit("click"));
    })("@transformMenu.start", function MatLegacyMenu_ng_template_0_Template_div_animation_transformMenu_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._onAnimationStart($event));
    })("@transformMenu.done", function MatLegacyMenu_ng_template_0_Template_div_animation_transformMenu_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._onAnimationDone($event));
    });
    i0.ɵɵelementStart(1, "div", 1);
    i0.ɵɵprojection(2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("id", ctx_r1.panelId)("ngClass", ctx_r1._classList)("@transformMenu", ctx_r1._panelAnimationState);
    i0.ɵɵattribute("aria-label", ctx_r1.ariaLabel || null)("aria-labelledby", ctx_r1.ariaLabelledby || null)("aria-describedby", ctx_r1.ariaDescribedby || null);
  }
}
const _c1 = ["mat-menu-item", ""];
function MatLegacyMenuItem__svg_svg_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 2);
    i0.ɵɵelement(1, "polygon", 3);
    i0.ɵɵelementEnd();
  }
}
class MatLegacyMenu extends _MatMenuBase {
  constructor(elementRef, ngZone, defaultOptions, changeDetectorRef) {
    super(elementRef, ngZone, defaultOptions, changeDetectorRef);
    this._elevationPrefix = 'mat-elevation-z';
    this._baseElevation = 4;
  }
  static {
    this.ɵfac = function MatLegacyMenu_Factory(t) {
      return new (t || MatLegacyMenu)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(MAT_MENU_DEFAULT_OPTIONS), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacyMenu,
      selectors: [["mat-menu"]],
      hostAttrs: ["ngSkipHydration", ""],
      hostVars: 4,
      hostBindings: function MatLegacyMenu_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-label", null)("aria-labelledby", null)("aria-describedby", null)("mat-id-collision", null);
        }
      },
      exportAs: ["matMenu"],
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_MENU_PANEL,
        useExisting: MatLegacyMenu
      }]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      consts: [["tabindex", "-1", "role", "menu", 1, "mat-menu-panel", 3, "keydown", "click", "id", "ngClass"], [1, "mat-menu-content"]],
      template: function MatLegacyMenu_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, MatLegacyMenu_ng_template_0_Template, 3, 6, "ng-template");
        }
      },
      dependencies: [i1.NgClass],
      styles: ["mat-menu{display:none}.mat-menu-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;max-height:calc(100vh - 48px);border-radius:4px;outline:0;min-height:64px;position:relative}.mat-menu-panel.ng-animating{pointer-events:none}.cdk-high-contrast-active .mat-menu-panel{outline:solid 1px}.mat-menu-content:not(:empty){padding-top:8px;padding-bottom:8px}.mat-menu-item{-webkit-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:rgba(0,0,0,0);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative}.mat-menu-item::-moz-focus-inner{border:0}.mat-menu-item[disabled]{cursor:default}[dir=rtl] .mat-menu-item{text-align:right}.mat-menu-item .mat-icon{margin-right:16px;vertical-align:middle}.mat-menu-item .mat-icon svg{vertical-align:top}[dir=rtl] .mat-menu-item .mat-icon{margin-left:16px;margin-right:0}.mat-menu-item[disabled]::after{display:block;position:absolute;content:\"\";top:0;left:0;bottom:0;right:0}.cdk-high-contrast-active .mat-menu-item{margin-top:1px}.mat-menu-item-submenu-trigger{padding-right:32px}[dir=rtl] .mat-menu-item-submenu-trigger{padding-right:16px;padding-left:32px}.mat-menu-submenu-icon{position:absolute;top:50%;right:16px;transform:translateY(-50%);width:5px;height:10px;fill:currentColor}[dir=rtl] .mat-menu-submenu-icon{right:auto;left:16px;transform:translateY(-50%) scaleX(-1)}.cdk-high-contrast-active .mat-menu-submenu-icon{fill:CanvasText}button.mat-menu-item{width:100%}.mat-menu-item .mat-menu-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}"],
      encapsulation: 2,
      data: {
        animation: [matMenuAnimations.transformMenu, matMenuAnimations.fadeInItems]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyMenu, [{
    type: Component,
    args: [{
      selector: 'mat-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      exportAs: 'matMenu',
      host: {
        '[attr.aria-label]': 'null',
        '[attr.aria-labelledby]': 'null',
        '[attr.aria-describedby]': 'null',
        'ngSkipHydration': '',
        // This binding is used to ensure that the component ID doesn't clash with the `MatMenu`.
        '[attr.mat-id-collision]': 'null'
      },
      animations: [matMenuAnimations.transformMenu, matMenuAnimations.fadeInItems],
      providers: [{
        provide: MAT_MENU_PANEL,
        useExisting: MatLegacyMenu
      }],
      template: "<ng-template>\n  <div\n    class=\"mat-menu-panel\"\n    [id]=\"panelId\"\n    [ngClass]=\"_classList\"\n    (keydown)=\"_handleKeydown($event)\"\n    (click)=\"closed.emit('click')\"\n    [@transformMenu]=\"_panelAnimationState\"\n    (@transformMenu.start)=\"_onAnimationStart($event)\"\n    (@transformMenu.done)=\"_onAnimationDone($event)\"\n    tabindex=\"-1\"\n    role=\"menu\"\n    [attr.aria-label]=\"ariaLabel || null\"\n    [attr.aria-labelledby]=\"ariaLabelledby || null\"\n    [attr.aria-describedby]=\"ariaDescribedby || null\">\n    <div class=\"mat-menu-content\">\n      <ng-content></ng-content>\n    </div>\n  </div>\n</ng-template>\n",
      styles: ["mat-menu{display:none}.mat-menu-panel{min-width:112px;max-width:280px;overflow:auto;-webkit-overflow-scrolling:touch;max-height:calc(100vh - 48px);border-radius:4px;outline:0;min-height:64px;position:relative}.mat-menu-panel.ng-animating{pointer-events:none}.cdk-high-contrast-active .mat-menu-panel{outline:solid 1px}.mat-menu-content:not(:empty){padding-top:8px;padding-bottom:8px}.mat-menu-item{-webkit-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:rgba(0,0,0,0);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;line-height:48px;height:48px;padding:0 16px;text-align:left;text-decoration:none;max-width:100%;position:relative}.mat-menu-item::-moz-focus-inner{border:0}.mat-menu-item[disabled]{cursor:default}[dir=rtl] .mat-menu-item{text-align:right}.mat-menu-item .mat-icon{margin-right:16px;vertical-align:middle}.mat-menu-item .mat-icon svg{vertical-align:top}[dir=rtl] .mat-menu-item .mat-icon{margin-left:16px;margin-right:0}.mat-menu-item[disabled]::after{display:block;position:absolute;content:\"\";top:0;left:0;bottom:0;right:0}.cdk-high-contrast-active .mat-menu-item{margin-top:1px}.mat-menu-item-submenu-trigger{padding-right:32px}[dir=rtl] .mat-menu-item-submenu-trigger{padding-right:16px;padding-left:32px}.mat-menu-submenu-icon{position:absolute;top:50%;right:16px;transform:translateY(-50%);width:5px;height:10px;fill:currentColor}[dir=rtl] .mat-menu-submenu-icon{right:auto;left:16px;transform:translateY(-50%) scaleX(-1)}.cdk-high-contrast-active .mat-menu-submenu-icon{fill:CanvasText}button.mat-menu-item{width:100%}.mat-menu-item .mat-menu-ripple{top:0;left:0;right:0;bottom:0;position:absolute;pointer-events:none}"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [MAT_MENU_DEFAULT_OPTIONS]
      }]
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * Single item inside of a `mat-menu`. Provides the menu item styling and accessibility treatment.
 * @deprecated Use `MatMenuItem` from `@angular/material/menu` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyMenuItem extends MatMenuItem {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyMenuItem_BaseFactory;
      return function MatLegacyMenuItem_Factory(t) {
        return (ɵMatLegacyMenuItem_BaseFactory || (ɵMatLegacyMenuItem_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyMenuItem)))(t || MatLegacyMenuItem);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MatLegacyMenuItem,
      selectors: [["", "mat-menu-item", ""]],
      hostAttrs: [1, "mat-focus-indicator"],
      hostVars: 20,
      hostBindings: function MatLegacyMenuItem_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.role)("tabindex", ctx._getTabIndex())("aria-disabled", ctx.disabled.toString())("disabled", ctx.disabled || null);
          i0.ɵɵclassProp("mat-menu-item", true)("mat-menu-item-highlighted", ctx._highlighted)("mat-menu-item-submenu-trigger", ctx._triggersSubmenu)("mat-mdc-menu-item", false)("mat-mdc-focus-indicator", false)("mdc-list-item", false)("mat-mdc-menu-item-highlighted", false)("mat-mdc-menu-item-submenu-trigger", false);
        }
      },
      inputs: {
        disabled: "disabled",
        disableRipple: "disableRipple"
      },
      exportAs: ["matMenuItem"],
      features: [i0.ɵɵProvidersFeature([{
        provide: MatMenuItem,
        useExisting: MatLegacyMenuItem
      }]), i0.ɵɵInheritDefinitionFeature],
      attrs: _c1,
      ngContentSelectors: _c0,
      decls: 3,
      vars: 3,
      consts: [["matRipple", "", 1, "mat-menu-ripple", 3, "matRippleDisabled", "matRippleTrigger"], ["class", "mat-menu-submenu-icon", "viewBox", "0 0 5 10", "focusable", "false", "aria-hidden", "true", 4, "ngIf"], ["viewBox", "0 0 5 10", "focusable", "false", "aria-hidden", "true", 1, "mat-menu-submenu-icon"], ["points", "0,0 5,5 0,10"]],
      template: function MatLegacyMenuItem_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵelement(1, "div", 0);
          i0.ɵɵtemplate(2, MatLegacyMenuItem__svg_svg_2_Template, 2, 0, "svg", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵproperty("matRippleDisabled", ctx.disableRipple || ctx.disabled)("matRippleTrigger", ctx._getHostElement());
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx._triggersSubmenu);
        }
      },
      dependencies: [i1.NgIf, i2.MatRipple],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyMenuItem, [{
    type: Component,
    args: [{
      selector: '[mat-menu-item]',
      exportAs: 'matMenuItem',
      inputs: ['disabled', 'disableRipple'],
      host: {
        '[attr.role]': 'role',
        '[class.mat-menu-item]': 'true',
        '[class.mat-menu-item-highlighted]': '_highlighted',
        '[class.mat-menu-item-submenu-trigger]': '_triggersSubmenu',
        '[attr.tabindex]': '_getTabIndex()',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[attr.disabled]': 'disabled || null',
        'class': 'mat-focus-indicator',
        // Classes added by the base class that should be removed.
        '[class.mat-mdc-menu-item]': 'false',
        '[class.mat-mdc-focus-indicator]': 'false',
        '[class.mdc-list-item]': 'false',
        '[class.mat-mdc-menu-item-highlighted]': 'false',
        '[class.mat-mdc-menu-item-submenu-trigger]': 'false'
      },
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [{
        provide: MatMenuItem,
        useExisting: MatLegacyMenuItem
      }],
      template: "<ng-content></ng-content>\n<div class=\"mat-menu-ripple\" matRipple\n     [matRippleDisabled]=\"disableRipple || disabled\"\n     [matRippleTrigger]=\"_getHostElement()\">\n</div>\n\n<svg\n  *ngIf=\"_triggersSubmenu\"\n  class=\"mat-menu-submenu-icon\"\n  viewBox=\"0 0 5 10\"\n  focusable=\"false\"\n  aria-hidden=\"true\"><polygon points=\"0,0 5,5 0,10\"/></svg>\n"
    }]
  }], null, null);
})();

// TODO(andrewseguin): Remove the kebab versions in favor of camelCased attribute selectors
/**
 * Directive applied to an element that should trigger a `mat-menu`.
 * @deprecated Use `MatMenuTrigger` from `@angular/material/menu` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyMenuTrigger extends _MatMenuTriggerBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyMenuTrigger_BaseFactory;
      return function MatLegacyMenuTrigger_Factory(t) {
        return (ɵMatLegacyMenuTrigger_BaseFactory || (ɵMatLegacyMenuTrigger_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyMenuTrigger)))(t || MatLegacyMenuTrigger);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatLegacyMenuTrigger,
      selectors: [["", "mat-menu-trigger-for", ""], ["", "matMenuTriggerFor", ""]],
      hostAttrs: [1, "mat-menu-trigger"],
      exportAs: ["matMenuTrigger"],
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyMenuTrigger, [{
    type: Directive,
    args: [{
      selector: `[mat-menu-trigger-for], [matMenuTriggerFor]`,
      host: {
        'class': 'mat-menu-trigger'
      },
      exportAs: 'matMenuTrigger'
    }]
  }], null, null);
})();

/**
 * Menu content that will be rendered lazily once the menu is opened.
 * @deprecated Use `MatMenuContent` from `@angular/material/menu` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyMenuContent extends _MatMenuContentBase {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵMatLegacyMenuContent_BaseFactory;
      return function MatLegacyMenuContent_Factory(t) {
        return (ɵMatLegacyMenuContent_BaseFactory || (ɵMatLegacyMenuContent_BaseFactory = i0.ɵɵgetInheritedFactory(MatLegacyMenuContent)))(t || MatLegacyMenuContent);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MatLegacyMenuContent,
      selectors: [["ng-template", "matMenuContent", ""]],
      features: [i0.ɵɵProvidersFeature([{
        provide: MAT_MENU_CONTENT,
        useExisting: MatLegacyMenuContent
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyMenuContent, [{
    type: Directive,
    args: [{
      selector: 'ng-template[matMenuContent]',
      providers: [{
        provide: MAT_MENU_CONTENT,
        useExisting: MatLegacyMenuContent
      }]
    }]
  }], null, null);
})();

/**
 * @deprecated Use `MatMenuModule` from `@angular/material/menu` instead. See https://material.angular.io/guide/mdc-migration for information about migrating.
 * @breaking-change 17.0.0
 */
class MatLegacyMenuModule {
  static {
    this.ɵfac = function MatLegacyMenuModule_Factory(t) {
      return new (t || MatLegacyMenuModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MatLegacyMenuModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER],
      imports: [CommonModule, MatCommonModule, MatRippleModule, OverlayModule, CdkScrollableModule, MatCommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MatLegacyMenuModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MatCommonModule, MatRippleModule, OverlayModule],
      exports: [CdkScrollableModule, MatCommonModule, MatLegacyMenu, MatLegacyMenuItem, MatLegacyMenuTrigger, MatLegacyMenuContent],
      declarations: [MatLegacyMenu, MatLegacyMenuItem, MatLegacyMenuTrigger, MatLegacyMenuContent],
      providers: [MAT_MENU_SCROLL_STRATEGY_FACTORY_PROVIDER]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MatLegacyMenu, MatLegacyMenuContent, MatLegacyMenuItem, MatLegacyMenuModule, MatLegacyMenuTrigger };
