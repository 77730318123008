/**
 * Sends dimensions event when the element is resized.
 *
 * @unstable
 */

import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

export interface ElementResizeEvent {
  dimensions: { width: number; height: number };
  position: { left: number; top: number };
}

@Directive({
  selector: '[elementResize]',
})
export class ElementResize implements OnInit, OnDestroy {
  private ro?: ResizeObserver;

  @Output()
  readonly elementResize = new EventEmitter<ElementResizeEvent>();

  constructor(
    readonly zone: NgZone,
    readonly elRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.ro = new ResizeObserver(([{ contentRect }]: ResizeObserverEntry[]) =>
        this.zone.run(() =>
          this.elementResize.emit({
            dimensions: { width: contentRect.width, height: contentRect.height },
            position: { left: contentRect.left, top: contentRect.top },
          }),
        ),
      );

      this.ro.observe(this.elRef.nativeElement);
    });
  }

  ngOnDestroy(): void {
    if (this.ro) {
      this.ro.disconnect();
    }
  }
}
