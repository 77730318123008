import { Directive, HostBinding, Input } from '@angular/core';

@Directive({ selector: '[loadingPlaceholder]' })
export class LoadingPlaceholderDirective {
  @Input()
  @HostBinding('class.loading-placeholder')
  loadingPlaceholder?: boolean;

  @Input()
  placeholderWidth?: number | string;

  @HostBinding('style.width')
  get styleWidth(): string {
    if (!this.loadingPlaceholder) {
      return;
    }

    const width = this.placeholderWidth;

    return typeof width === 'number' ? `${width}px` : width;
  }

  @HostBinding('style.height')
  get styleHeight(): string {
    return this.loadingPlaceholder ? '100%' : null;
  }
}
