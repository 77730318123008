/**
 * Sends cursor location / selection on input click / input.
 *
 * @unstable
 */

import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[inputSelection]',
})
export class InputSelection {
  @Output() selectionChange = new EventEmitter();

  @HostListener('click') onClick(): void {
    this.emitSelection();
  }

  @HostListener('keydown') onChange(): void {
    this.emitSelection();
  }

  constructor(readonly elRef: ElementRef) {}

  private emitSelection(): void {
    const element = this.elRef.nativeElement;

    const selection = {
      direction: element.selectionDirection,
      start: element.selectionStart,
      end: element.selectionEnd,
      text: element.value.substr(element.selectionStart, element.selectionEnd - element.selectionStart),
    };

    this.selectionChange.emit(selection);
  }
}
