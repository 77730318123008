/**
 * Input debouncer will debounce the ngModelChange event.
 *
 * @unstable
 */

import { Subject, Subscription } from 'rxjs';

import { debounceTime } from 'rxjs/operators';

import { NgModel } from '@angular/forms';
import { Directive, OnInit, OnDestroy, EventEmitter, HostListener, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: '[inputDebouncer]',
})
export class InputDebouncer implements OnInit, OnDestroy {
  private timeout: number | null = null;

  private activeValue: string | null = null;
  private currentValue: string | null = null;

  private debouncerSub: Subscription | null = null;

  private debouncer: Subject<any> = new Subject<any>();

  private emitter: EventEmitter<any> | null = null;
  private listener: EventEmitter<any> = new EventEmitter();

  @HostListener('blur') onBlur(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    this.zone.runOutsideAngular(() => {
      this.timeout = window.setTimeout(() => {
        this.zone.run(() => {
          this.activeValue = null;

          this.model.control.setValue(this.currentValue, { emitViewToModelChange: false });
        });
      }, 5000);
    });
  }

  @HostListener('focus') onFocus(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  constructor(
    readonly elRef: ElementRef,
    readonly model: NgModel,
    readonly zone: NgZone,
  ) {}

  ngOnInit(): void {
    this.emitter = this.model.update;

    this.model.update = this.listener;

    this.debouncerSub = this.debouncer.pipe(debounceTime(500)).subscribe((value: string) => {
      if (this.emitter instanceof EventEmitter) {
        this.emitter.emit(value);
      }
    });

    this.listener.subscribe((value: string) => {
      this.activeValue = value;

      this.debouncer.next(value);
    });

    if (this.model instanceof NgModel && this.model.valueChanges !== null) {
      this.model.valueChanges.subscribe((value: string) => {
        this.currentValue = value;

        if (this.activeValue != null && this.activeValue !== this.currentValue) {
          this.listener.next(this.currentValue);

          this.model.control.setValue(this.activeValue, { emitViewToModelChange: false });
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }

    if (this.debouncerSub) {
      this.debouncerSub.unsubscribe();
    }

    if (this.activeValue != null) {
      if (this.emitter instanceof EventEmitter) {
        this.emitter.emit(this.activeValue);
      }
    }
  }
}
