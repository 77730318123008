/**
 * Running number transition with D3.
 *
 * @unstable
 */

import * as d3 from 'd3';

import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[numberTransition]',
})
export class NumberTransition implements OnChanges {
  private base: any;

  private transition: any;

  @Input() data: any;

  @Input() duration: any;

  constructor(readonly elRef: ElementRef) {
    this.setBase();
  }

  ngOnChanges(): void {
    this.setTransition(this.duration);

    if (this.data != null) {
      this.setData(this.data);
    }
  }

  private setBase(): void {
    this.base = d3.select(this.elRef.nativeElement);
  }

  private setData(data): void {
    // Join new data
    this.base.selectAll('text').data([data]);

    // Update the data
    this.base.transition(this.transition).tween('text', function () {
      const that = d3.select(this);

      const start = that.text() ? d3.select(this).text() : 0;

      const i = d3.interpolate(start, data);
      const prec = (data + '').split('.');
      const round = prec.length > 1 ? Math.pow(10, prec[1].length) : 1;

      return function (t) {
        that.text(Math.round(i(t) * round) / round);
      };
    });
  }

  private setTransition(duration): void {
    this.transition = d3.transition().duration(duration);
  }
}
