import { ConnectedPosition, Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationStart, Router } from '@angular/router';
import { ZefThemePalette } from '@shared/models/color.model';
import { BasicModule } from '@shared/modules/basic.module';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { merge, Observable, of } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

export type InlineDialogCtx = { $implicit?: any; [key: string]: any };

@Directive({
  selector: '[customDialog]',
})
export class InlineCustomDialog {
  constructor(readonly tr: TemplateRef<any>) {}
}

@Directive({
  selector: '[dialogTitle]',
})
export class InlineDialogTitle {
  constructor(readonly tr: TemplateRef<any>) {}
}

@Directive({
  selector: '[dialogContent]',
})
export class InlineDialogContent {
  constructor(readonly tr: TemplateRef<any>) {}
}

@Directive({
  selector: '[dialogAction]',
})
export class InlineDialogAction {
  constructor(readonly tr: TemplateRef<any>) {}
}

export interface InlineDialogRef {
  close: Observable<void>;

  openDialog(connectedTo: ElementRef<HTMLElement>, ctx: InlineDialogCtx): void;
}

@Directive({
  selector: '[inlineDialogTriggerFor]',
  exportAs: 'inlineDialogTrigger',
  providers: [LifecycleHooks],
})
export class InlineDialogTriggerFor implements OnChanges {
  @Input()
  inlineDialogTriggerFor?: InlineDialogRef;

  @Input()
  inlineDialogCtx?: InlineDialogCtx;

  @Input()
  inlineDialogDisabled?: boolean;

  @Input()
  matMenuTrigger?: MatMenuTrigger;

  constructor(
    private element: ElementRef<HTMLElement>,
    private lh: LifecycleHooks,
    @Optional() private menuTrigger?: MatMenuTrigger,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inlineDialogTriggerFor) {
      this.inlineDialogTriggerFor?.close.pipe(takeUntil(this.lh.destroy.pipe(delay(1)))).subscribe(() => {
        (this.matMenuTrigger || this.menuTrigger)?.closeMenu?.();
      });
    }
  }

  @HostListener('click.c', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.inlineDialogDisabled && this.inlineDialogTriggerFor) {
      if (!this.matMenuTrigger) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
      }

      this.openDialog();
    }
  }

  openDialog(): void {
    const connectedTo =
      (this.matMenuTrigger as any)?._elementRef instanceof ElementRef
        ? (this.matMenuTrigger as any)?._elementRef
        : this.element;

    this.inlineDialogTriggerFor.openDialog(connectedTo, this.inlineDialogCtx);
  }
}

@Component({
  selector: 'zef-inline-dialog',
  templateUrl: './inline-dialog.component.html',
  styleUrls: ['./inline-dialog.component.scss'],
  exportAs: 'inlineDialog',
})
export class InlineDialog implements OnDestroy, OnChanges, InlineDialogRef {
  @ViewChild(CdkPortal, { static: false })
  portal?: CdkPortal;

  @ContentChild(InlineCustomDialog, { static: false })
  customDialog?: InlineCustomDialog;

  @ContentChild(InlineDialogTitle, { static: false })
  dialogTitle?: InlineDialogTitle;

  @ContentChild(InlineDialogContent, { static: false })
  dialogContent?: InlineDialogContent;

  @ContentChild(InlineDialogAction, { static: false })
  dialogAction?: InlineDialogAction;

  @Input()
  loading?: boolean;

  @Input()
  disableAction?: boolean;

  @Input()
  color: ZefThemePalette = 'alert';

  @Input()
  positions: ConnectedPosition[];

  @Output()
  readonly confirm = new EventEmitter<InlineDialogCtx>();

  @Output()
  readonly close = new EventEmitter<void>();

  get open(): boolean {
    return !!this.overlayRef?.hasAttached();
  }

  get ctx(): InlineDialogCtx {
    return this._ctx;
  }

  private overlayRef: OverlayRef;

  private _ctx: InlineDialogCtx;

  private waitClose?: boolean;

  constructor(
    private overlay: Overlay,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.waitClose && changes.loading && !this.loading) {
      this.waitClose = false;
      this.closeDialog();
    }
  }

  ngOnDestroy(): void {
    this.close.emit();
    this.overlayRef?.dispose();
  }

  toggle(connectedTo: ElementRef<HTMLElement>, ctx: InlineDialogCtx): void {
    if (this.open) {
      this.closeDialog();
    } else {
      this.openDialog(connectedTo, ctx);
    }
  }

  openDialog(connectedTo: ElementRef<HTMLElement>, ctx?: InlineDialogCtx): void {
    if (this.open) {
      return;
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    const withPositions = this.positions || [
      { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
      { originX: 'start', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
      { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
    ];

    const config = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedTo)
        .withPositions(withPositions)
        .withFlexibleDimensions(true)
        .withGrowAfterOpen(true),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disposeOnNavigation: true,
    });

    this.overlayRef = this.overlay.create(config);

    merge(this.overlayRef.backdropClick(), this.router.events.pipe(filter((event) => event instanceof NavigationStart)))
      .pipe(takeUntil(this.close))
      .subscribe(() => this.closeDialog());

    this._ctx = ctx;
    this.overlayRef.attach(this.portal);

    setTimeout(() => this.overlayRef?.updatePosition());
  }

  closeDialog(): void {
    if (this.open && !this.loading) {
      this.waitClose = false;
      this.close.emit();
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.cd.markForCheck();
    }
  }

  onConfirmClick(): void {
    if (!this.loading && !this.waitClose) {
      this.waitClose = true;
      this.confirm.emit(this.ctx);

      setTimeout(() => {
        if (!this.loading) {
          this.closeDialog();
        }
      });
    }
  }
}

@Directive()
export abstract class CustomInlineDialog implements InlineDialogRef, OnInit {
  close: Observable<void> = of(void 0);

  @Output()
  readonly confirm = new EventEmitter<InlineDialogCtx>();

  @ViewChild(InlineDialog, { static: true })
  id?: InlineDialog;

  @HostBinding('class.zef-custom-inline-dialog')
  readonly customDialog: boolean = true;

  ngOnInit(): void {
    this.close = this.id?.close;
  }

  openDialog(connectedTo: ElementRef<HTMLElement>, ctx: InlineDialogCtx) {
    this.id?.openDialog(connectedTo, ctx);
  }
}

@Directive({
  selector: '[inlineDialogClose]',
})
export class InlineDialogClose {
  @HostListener('click')
  onClick(): void {
    this.id.closeDialog();
  }

  constructor(@Host() private id: InlineDialog) {}
}

@NgModule({
  imports: [CommonModule, PortalModule, OverlayModule, MatIconModule, MatButtonModule, BasicModule],
  declarations: [
    InlineDialog,
    InlineDialogClose,
    InlineDialogTitle,
    InlineDialogContent,
    InlineDialogAction,
    InlineDialogTriggerFor,
    InlineCustomDialog,
  ],
  exports: [
    InlineDialog,
    InlineDialogClose,
    InlineDialogTitle,
    InlineDialogContent,
    InlineDialogAction,
    InlineDialogTriggerFor,
    InlineCustomDialog,
  ],
})
export class InlineDialogModule {}
