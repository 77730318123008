import { Component, ChangeDetectionStrategy, Input, HostBinding, SimpleChanges, OnChanges } from '@angular/core';
import { interval, ReplaySubject, Observable, animationFrameScheduler, BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { random, range } from '@shared/utilities/array.utilities';

interface SpinnerItem {
  icon: string;
  leafs: number[];
}

type SpinnerMode = 'determinate' | 'indeterminate';

type SpinnerSize = 'small' | 'medium' | 'large' | 'larger' | 'huge';

@Component({
  selector: 'zef-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Spinner implements OnChanges {
  readonly speed = 1000;

  private readonly min = 0.3;

  private readonly sizes: Record<SpinnerSize, number> = {
    small: 16,
    medium: 24,
    large: 32,
    larger: 48,
    huge: 88,
  };

  private readonly spinners: SpinnerItem[] = range(10, 1).map((id) => ({ icon: `snow_${id}`, leafs: range(6) }));

  private readonly mode$ = new BehaviorSubject<SpinnerMode>('indeterminate');

  private readonly progress$ = new BehaviorSubject(0);

  private readonly modes: Record<SpinnerMode, (leafs: number[]) => Observable<number[]>> = {
    indeterminate: (leafs: number[]) =>
      interval(this.speed / leafs.length, animationFrameScheduler).pipe(
        startWith(-1),
        map((emit) =>
          leafs.map(
            (leaf) => 1 - (1 - this.min) * (((emit + leafs.length - leaf) % leafs.length) / (leafs.length - 1)),
          ),
        ),
      ),
    determinate: (leafs: number[]) =>
      this.progress$.pipe(
        map((progress) =>
          leafs.map((leaf) =>
            Math.min(
              1,
              Math.max(
                this.min,
                ((progress - leaf * (1 / leafs.length)) / (1 / leafs.length)) * (1 - this.min) + this.min,
              ),
            ),
          ),
        ),
      ),
  };

  @Input()
  size: SpinnerSize = 'medium';

  @Input()
  color: string = 'primary';

  @Input()
  @HostBinding('class.hidden')
  hide?: boolean;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  _size = this.sizes[this.size];

  @Input()
  set progress(progress: number) {
    this.progress$.next(Math.max(0, Math.min(progress, 1)));
  }

  @Input()
  set mode(mode: SpinnerMode) {
    this.mode$.next(mode);
  }

  readonly spinner$ = new ReplaySubject<SpinnerItem>(1);

  readonly leaf5: Observable<number[]> = combineLatest([this.spinner$, this.mode$]).pipe(
    switchMap(([{ leafs }, mode]) => this.modes[mode](leafs)),
  );

  readonly trackByLeaf = (i: number) => i;

  constructor() {
    this.spinner$.next(random(this.spinners));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size) {
      this._size = this.sizes[this.size];
    }
  }
}
