import { Directive, Self } from '@angular/core';

import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';

@Directive({
  selector: '[zefSidenavMenu]',
})
export class SidenavMenu {
  constructor(@Self() readonly menu: MatMenu) {}
}
