/**
 * Transforms any color string to HEX color string.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexifyColor',
})
export class HexifyColor implements PipeTransform {
  constructor() {}

  static valueToHex(value: string): string {
    const hex = parseInt(value, 10).toString(16);

    return hex.length === 1 ? '0' + hex : hex;
  }

  static hexToHex(r: string, g: string, b: string): string {
    return `#${r}${r}${g}${g}${b}${b}`;
  }

  static rgbToHex(r: string, g: string, b: string): string {
    return '#' + HexifyColor.valueToHex(r) + HexifyColor.valueToHex(g) + HexifyColor.valueToHex(b);
  }

  static rgbStringToHex(rgb: string): string {
    return (
      rgb &&
      `#${rgb
        .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        ?.slice(1)
        .map(HexifyColor.valueToHex)
        .join('')}`
    );
  }

  public transform(value: string | null): string | null {
    if (!value) {
      return null;
    }

    let hex = value;

    if (hex.indexOf('#') === 0) {
      if (hex.length === 4) {
        hex = HexifyColor.hexToHex(hex[1], hex[2], hex[3]);
      }
    } else if (hex.indexOf('rgb') === 0) {
      const rgb = hex
        .replace(/rgba?/, '')
        .replace(/(\(|\))/g, '')
        .split(',');

      if (rgb.length >= 3 && rgb.length <= 4) {
        hex = HexifyColor.rgbToHex(rgb[0], rgb[1], rgb[2]);
      }
    }

    return hex.toUpperCase();
  }
}
