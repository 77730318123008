/**
 * Translates given property text with given locale.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

import { PropertyStore } from '@shared/services/property-store.service';

@Pipe({
  name: 'propertyText',
  pure: false,
})
export class PropertyText implements PipeTransform {
  constructor(readonly propStore: PropertyStore) {}

  transform(value: string, locale?: string, context?: any): any {
    return this.propStore.transform(value, locale, context);
  }
}
