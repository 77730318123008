import { ViewSize } from '@shared/enums/view-size.enum';
import { BreakPoint } from '@shared/modules/media-monitor.module';

export const ZEF_BREAKPOINTS: BreakPoint[] = [
  {
    alias: ViewSize.Tiny,
    suffix: 'Tn',
    overlapping: false,
    mediaQuery: 'screen and (max-width: 319px)',
  },
  {
    alias: ViewSize.ExtraSmall,
    suffix: 'Xs',
    overlapping: false,
    mediaQuery: 'screen and (min-width: 320px) and (max-width: 399px)',
  },
  {
    alias: 'lt-xs',
    suffix: 'LtXs',
    overlapping: true,
    mediaQuery: 'screen and (max-width: 319px)',
  },
  {
    alias: 'gt-xs',
    suffix: 'GtXs',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 400px)',
  },
  {
    alias: ViewSize.Small,
    suffix: 'Sm',
    overlapping: false,
    mediaQuery: 'screen and (min-width: 400px) and (max-width: 767px)',
  },
  {
    alias: 'lt-sm',
    suffix: 'LtSm',
    overlapping: true,
    mediaQuery: 'screen and (max-width: 399px)',
  },
  {
    alias: 'gt-sm',
    suffix: 'GtSm',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 768px)',
  },
  {
    alias: ViewSize.Medium,
    suffix: 'Md',
    overlapping: false,
    mediaQuery: 'screen and (min-width: 768px) and (max-width: 1023px)',
  },
  {
    alias: 'lt-md',
    suffix: 'LtMd',
    overlapping: true,
    mediaQuery: 'screen and (max-width: 767px)',
  },
  {
    alias: 'gt-md',
    suffix: 'GtMd',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 1024px)',
  },
  {
    alias: ViewSize.Large,
    suffix: 'Lg',
    overlapping: false,
    mediaQuery: 'screen and (min-width: 1024px) and (max-width: 1199px)',
  },
  {
    alias: 'lt-lg',
    suffix: 'LtLg',
    overlapping: true,
    mediaQuery: 'screen and (max-width: 1023px)',
  },
  {
    alias: 'gt-lg',
    suffix: 'GtLg',
    overlapping: true,
    mediaQuery: 'screen and (min-width: 1200px)',
  },
  {
    alias: ViewSize.ExtraLarge,
    suffix: 'Xl',
    overlapping: false,
    mediaQuery: 'screen and (min-width: 1200px)',
  },
];
