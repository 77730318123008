import { Observable, BehaviorSubject } from 'rxjs';
import { debounceTime, map, filter } from 'rxjs/operators';

import { Directive, ElementRef, Injectable, AfterViewInit, OnDestroy, Input, NgZone } from '@angular/core';

import { shareRef } from '@shared/operators/share-ref.operator';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { CdkObserveContent, ContentObserver } from '@angular/cdk/observers';

export interface CanSetWidth {
  width?: number;
  category: string;
  el: ElementRef<HTMLElement>;
  event: Observable<MutationRecord[]>;
}

@Injectable()
export class EqualWidthService {
  items: Record<string, CanSetWidth[]> = {};

  private refresh$ = new BehaviorSubject<undefined | string>(void 0);

  private update$: Record<string, Observable<number>> = {};

  registerItem(item: CanSetWidth): Observable<number> {
    if (!this.items[item.category]) {
      this.items[item.category] = [];
      this.update$[item.category] = this.refresh$.pipe(
        filter((category) => category === item.category),
        debounceTime(1),
        map(() => this.getWidth(this.items[item.category] || [])),
        shareRef(),
      );

      item.event.subscribe(() => this.refresh$.next(item.category));
    }

    if (!this.items[item.category].includes(item)) {
      this.items[item.category].push(item);
    }

    this.refresh$.next(item.category);

    return this.update$[item.category];
  }

  unregisterItem(item: CanSetWidth): void {
    const idx = this.items[item.category]?.indexOf(item);

    if (idx > -1) {
      this.items[item.category]?.splice(idx, 1);
    }

    this.refresh$.next(item.category);
  }

  private getWidth(items: CanSetWidth[]): number {
    return items.reduce((a, { el: { nativeElement } }) => {
      nativeElement.style.width = '';

      const width = nativeElement.offsetWidth;

      return a < width ? width : a;
    }, 0);
  }
}

@Directive({
  selector: '[equalWidth]',
  providers: [LifecycleHooks],
})
export class EqualWidthDirective extends CdkObserveContent implements CanSetWidth, AfterViewInit, OnDestroy {
  @Input('equalWidth')
  category: string = '';

  constructor(
    readonly el: ElementRef<HTMLElement>,
    private es: EqualWidthService,
    private lh: LifecycleHooks,
    co: ContentObserver,
    zone: NgZone,
  ) {
    super(co, el, zone);
  }

  ngAfterViewInit(): void {
    this.lh
      .untilDestroy(this.es.registerItem(this))
      .subscribe((width) => (this.el.nativeElement.style.width = `${width}px`));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.es.unregisterItem(this);
  }
}

@Directive({
  selector: '[equalWidthContainer]',
  providers: [EqualWidthService],
})
export class EqualWidthContainer {}
