<!-- using svg because the font might not be ready yet -->

<svg class="body" width="48" height="48" viewBox="0 0 768 768" xmlns="http://www.w3.org/2000/svg">
  <g class="layer">
    <path
      d="m346.168,121.212c-15.233,30.239 -23.81,64.404 -23.81,100.574c0,123.632 100.224,223.856 223.856,223.856c36.169,0 70.334,-8.578 100.573,-23.81l0,163.315c0,34.044 -27.598,61.642 -61.642,61.642l-139.504,0l0,-100.574c0,-34.044 -27.598,-61.642 -61.642,-61.642s-61.642,27.598 -61.642,61.642l0,100.573l-139.505,0c-34.044,0 -61.642,-27.598 -61.642,-61.642l0,-139.504l100.574,0c34.044,0 61.642,-27.598 61.642,-61.642s-27.598,-61.642 -61.642,-61.642l-100.574,0l0,-139.505c0,-34.044 27.598,-61.642 61.642,-61.642l163.315,0l0.001,0.001z"
      fill="#112539"
    />
  </g>
</svg>

<svg class="head" width="48" height="48" viewBox="0 0 768 768" xmlns="http://www.w3.org/2000/svg">
  <g class="layer">
    <path
      d="m545.939,96c69.621,0 126.061,56.439 126.061,126.061s-56.439,126.061 -126.061,126.061c-69.621,0 -126.061,-56.439 -126.061,-126.061s56.439,-126.061 126.061,-126.061z"
      fill="#cc9256"
    />
  </g>
</svg>
