<mat-icon
  *ngFor="let leaf of leaf5 | async; trackBy: trackByLeaf; let count = count; let index = index"
  class="leaf"
  [ngClass]="'zef-icon-' + size + ' zef-color-' + color"
  [style.transform]="'rotate(' + (360 / count) * index + 'deg)'"
  [style.opacity]="leaf"
  [style.transition]="speed / count + 'ms linear opacity'"
>
  {{ (spinner$ | async)?.icon }}
</mat-icon>
