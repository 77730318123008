/**
 * Formats plain text strings into simple html.
 *
 * @unstable
 */

// TODO: This is not needed once we move to wysiwyg editing

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText',
})
export class FormatText implements PipeTransform {
  constructor() {}

  public transform(text: string = ''): any {
    if (!text) {
      return '';
    }

    return text.replace(/\n/g, '<br>');
  }
}
