import { chipPattern } from '@shared/enums/properties.enum';
import { Subject, ReplaySubject } from 'rxjs';
import { SurveyData, OutcomeData } from '@shared/models/survey.model';
import { TeamData } from '@shared/models/account.model';
import { PlayerOutcome } from '@player/shared/models/player.model';
import { sanitizeHtml } from '@shared/utilities/string.utilities';
/**
 * Service for storing and retrieving property values.
 *
 * @unstable
 */

export interface IStore {
  get(value: any, locale: string, context?: any): any;
  set(key: any, value: any, locale: string, context?: any): void;
  transform(value: string, locale: string, context?: any): string;
}

export abstract class PropertyStore implements IStore {
  static readonly chipPattern = chipPattern;

  protected onDestroy = new Subject<void>();
  protected propMap = new Map<any, string>();

  readonly survey = new ReplaySubject<SurveyData>(1);
  readonly team = new ReplaySubject<TeamData>(1);
  readonly outcomes = new ReplaySubject<OutcomeData[]>(1);
  readonly scoredOutcomes = new ReplaySubject<PlayerOutcome[]>(1);

  abstract get(value: any, locale?: string, context?: any): string | null;
  abstract set(key: any, value: string, context?: any);
  abstract init(data?: any);

  public destroy() {
    this.onDestroy.next();
  }

  public transform(value: string, locale?: string | null, context?: string) {
    if (value == null) {
      return '';
    }

    const text = value
      .split(PropertyStore.chipPattern)
      .filter((string) => string)
      .map((elem) => {
        if (elem.match(PropertyStore.chipPattern)) {
          const strippedText = elem.replace(/{|}/g, '');

          // TODO: Decide if we want to remove the undefined property chip
          return this.get(strippedText as any, locale, context);
        } else {
          return elem;
        }
      })
      .join('');

    return sanitizeHtml(text);
  }
}
