import { animationFrameScheduler, concat, interval, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap, takeWhile, tap } from 'rxjs/operators';

export function easeInOutCubic(t): number {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

const elapsed = () => {
  const start = animationFrameScheduler.now();

  return interval(0, animationFrameScheduler).pipe(map(() => animationFrameScheduler.now() - start));
};

const duration = (time: number) =>
  concat(
    elapsed().pipe(
      map((ms) => ms / time),
      takeWhile((t) => t < 1),
    ),
    of(1),
  );
export function tweenTo(time: number, run: (next: number) => void, easing: (t: number) => number = easeInOutCubic) {
  return (source: Observable<[number, number]>) =>
    source.pipe(
      switchMap(([from, to]) =>
        duration(time).pipe(
          map(easing),
          map((delta) => (to - from) * delta + from),
          distinctUntilChanged(),
          tap((next) => run(next)),
          map((next) => to === next),
          filter((stop) => stop),
          mapTo(to),
        ),
      ),
    );
}
