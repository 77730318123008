import { Directive, ViewChild } from '@angular/core';

import { SidenavMenu } from '@shared/modules/sidenav/directives/sidenav-menu.directive';
import { SidenavTitle } from '@shared/modules/sidenav/directives/sidenav-title.directive';
import { SidenavDiscardDialog } from '@shared/modules/sidenav/directives/sidenav-discard-dialog.directive';
import { SidenavButtonPrimary } from '@shared/modules/sidenav/directives/sidenav-button-primary.directive';
import { SidenavButtonSecondary } from '@shared/modules/sidenav/directives/sidenav-button-secondary.directive';

@Directive()
export abstract class SidenavHeaderDirective {
  @ViewChild(SidenavTitle, { static: true })
  title?: SidenavTitle;

  @ViewChild(SidenavMenu, { static: true })
  menu?: SidenavMenu;

  @ViewChild(SidenavDiscardDialog, { static: true })
  discardDialog?: SidenavDiscardDialog;

  @ViewChild(SidenavButtonPrimary, { static: true })
  buttonPrimary?: SidenavButtonPrimary;

  @ViewChild(SidenavButtonSecondary, { static: true })
  buttonSecondary?: SidenavButtonSecondary;
}
