import { Observable } from 'rxjs';

import { environment } from '@env/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UnsplashApi {
  constructor(private http: HttpClient) {}

  attribute(imageId: string): Observable<void> {
    return this.http.get<void>(`https://views.unsplash.com/v`, {
      params: {
        app_id: environment.unsplash.appId.toString(),
        photo_id: imageId,
      },
    });
  }
}
