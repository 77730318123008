/*
 * Sanitize through angular security checker for the given html string.
 *
 * @unstable
 */

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtml implements PipeTransform {
  constructor(readonly sanitizer: DomSanitizer) {}

  public transform(html: string | null): SafeHtml | null {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }
}
