import { Directive, Self } from '@angular/core';

import { InlineDialog } from '@shared/components/inline-dialog/inline-dialog.component';

@Directive({
  selector: '[zefSidenavDiscardDialog]',
})
export class SidenavDiscardDialog {
  constructor(@Self() readonly dialog: InlineDialog) {}
}
