import { DomPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  ComponentFactoryResolver,
  Directive,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[zefPortal]',
})
export class Portal implements AfterViewInit, OnDestroy {
  private host: DomPortalOutlet;
  private portal: TemplatePortal;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngAfterViewInit(): void {
    const target = document.querySelector('.zef-portal');
    if (target) {
      this.host = new DomPortalOutlet(
        document.querySelector('.zef-portal'),
        this.componentFactoryResolver,
        this.appRef,
        this.injector,
      );

      this.portal = new TemplatePortal(this.templateRef, this.viewContainerRef);

      if (this.host) {
        this.host.attach(this.portal);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.host) {
      this.host.detach();
    }
  }
}
