import * as i0 from '@angular/core';
import { Directive, Optional, Input, Output, NgModule } from '@angular/core';
import { Subject, Subscription, Observable, tap, map, distinctUntilChanged, filter } from 'rxjs';
import * as i1 from 'ngx-scrollbar';
import * as i2 from '@angular/cdk/bidi';
class ReachedFunctions {
  static reachedTop(offset, e) {
    return ReachedFunctions.reached(-e.target.scrollTop, 0, offset);
  }
  static reachedBottom(offset, e) {
    return ReachedFunctions.reached(e.target.scrollTop + e.target.clientHeight, e.target.scrollHeight, offset);
  }
  static reachedStart(offset, e, direction, rtlScrollAxisType) {
    if (direction === 'rtl') {
      if (rtlScrollAxisType === 1 /* RtlScrollAxisType.NEGATED */) {
        return ReachedFunctions.reached(e.target.scrollLeft, 0, offset);
      }
      if (rtlScrollAxisType === 2 /* RtlScrollAxisType.INVERTED */) {
        return ReachedFunctions.reached(-e.target.scrollLeft, 0, offset);
      }
      return ReachedFunctions.reached(e.target.scrollLeft + e.target.clientWidth, e.target.scrollWidth, offset);
    }
    return ReachedFunctions.reached(-e.target.scrollLeft, 0, offset);
  }
  static reachedEnd(offset, e, direction, rtlScrollAxisType) {
    if (direction === 'rtl') {
      if (rtlScrollAxisType === 1 /* RtlScrollAxisType.NEGATED */) {
        return ReachedFunctions.reached(-(e.target.scrollLeft - e.target.clientWidth), e.target.scrollWidth, offset);
      }
      if (rtlScrollAxisType === 2 /* RtlScrollAxisType.INVERTED */) {
        return ReachedFunctions.reached(-(e.target.scrollLeft + e.target.clientWidth), e.target.scrollWidth, offset);
      }
      return ReachedFunctions.reached(-e.target.scrollLeft, 0, offset);
    }
    return ReachedFunctions.reached(e.target.scrollLeft + e.target.clientWidth, e.target.scrollWidth, offset);
  }
  static reached(currPosition, targetPosition, offset) {
    return currPosition >= targetPosition - offset;
  }
}
class ScrollReached {
  constructor(scrollbar, zone) {
    this.scrollbar = scrollbar;
    this.zone = zone;
    /** offset: Reached offset value in px */
    this.offset = 0;
    /**
     * Stream that emits scroll event when `NgScrollbar.scrolled` is initialized.
     *
     * **NOTE:** This subject is used to hold the place of `NgScrollbar.scrolled` when it's not initialized yet
     */
    this.scrollEvent = new Subject();
    /** subscription: Scrolled event subscription, used to unsubscribe from the event on destroy */
    this.subscription = Subscription.EMPTY;
    /** A stream used to assign the reached output */
    this.reachedEvent = new Observable(subscriber => this.scrollReached().subscribe(_ => Promise.resolve().then(() => this.zone.run(() => subscriber.next(_)))));
    if (!scrollbar) {
      console.warn('[NgScrollbarReached Directive]: Host element must be an NgScrollbar component.');
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  scrollReached() {
    // current event
    let currEvent;
    return this.scrollEvent.pipe(tap(e => currEvent = e),
    // Check if scroll has reached
    map(e => this.reached(this.offset, e)),
    // Distinct until reached value has changed
    distinctUntilChanged(),
    // Emit only if reached is true
    filter(reached => reached),
    // Return scroll event
    map(() => currEvent));
  }
  static {
    this.ɵfac = function ScrollReached_Factory(t) {
      return new (t || ScrollReached)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ScrollReached,
      inputs: {
        offset: [i0.ɵɵInputFlags.None, "reachedOffset", "offset"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollReached, [{
    type: Directive
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, {
    offset: [{
      type: Input,
      args: ['reachedOffset']
    }]
  });
})();
class VerticalScrollReached extends ScrollReached {
  constructor(scrollbar, zone) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
  }
  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      // Fix the viewport size in case the rendered size is not rounded
      const fixedSize = Math.round(this.scrollbar.viewport.nativeElement.getBoundingClientRect().height);
      this.scrollbar.viewport.nativeElement.style.height = `${fixedSize}px`;
      this.subscription = this.scrollbar.verticalScrolled.subscribe(this.scrollEvent);
    });
  }
  static {
    this.ɵfac = function VerticalScrollReached_Factory(t) {
      return new (t || VerticalScrollReached)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: VerticalScrollReached,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VerticalScrollReached, [{
    type: Directive
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class HorizontalScrollReached extends ScrollReached {
  constructor(scrollbar, zone) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
  }
  ngOnInit() {
    this.zone.runOutsideAngular(() => {
      // Fix the viewport size in case the rendered size is not rounded
      const fixedSize = Math.round(this.scrollbar.viewport.nativeElement.getBoundingClientRect().width);
      this.scrollbar.viewport.nativeElement.style.width = `${fixedSize}px`;
      this.subscription = this.scrollbar.horizontalScrolled.subscribe(this.scrollEvent);
    });
  }
  static {
    this.ɵfac = function HorizontalScrollReached_Factory(t) {
      return new (t || HorizontalScrollReached)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: HorizontalScrollReached,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HorizontalScrollReached, [{
    type: Directive
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
class NgScrollbarReachedTop extends VerticalScrollReached {
  constructor(scrollbar, zone) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
    /** Stream that emits when scroll has reached the top */
    this.reachedTop = this.reachedEvent;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  /**
   * Check if scroll has reached the top (vertically)
   * @param offset Scroll offset
   * @param e Scroll event
   */
  reached(offset, e) {
    return ReachedFunctions.reachedTop(offset, e);
  }
  static {
    this.ɵfac = function NgScrollbarReachedTop_Factory(t) {
      return new (t || NgScrollbarReachedTop)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollbarReachedTop,
      selectors: [["", "reachedTop", ""], ["", "reached-top", ""]],
      outputs: {
        reachedTop: "reachedTop"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollbarReachedTop, [{
    type: Directive,
    args: [{
      selector: '[reachedTop], [reached-top]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, {
    reachedTop: [{
      type: Output
    }]
  });
})();
class NgScrollbarReachedBottom extends VerticalScrollReached {
  constructor(scrollbar, zone) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
    /** Stream that emits when scroll has reached the bottom */
    this.reachedBottom = this.reachedEvent;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  /**
   * Check if scroll has reached the bottom (vertically)
   * @param offset Scroll offset
   * @param e Scroll event
   */
  reached(offset, e) {
    return ReachedFunctions.reachedBottom(offset, e);
  }
  static {
    this.ɵfac = function NgScrollbarReachedBottom_Factory(t) {
      return new (t || NgScrollbarReachedBottom)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollbarReachedBottom,
      selectors: [["", "reachedBottom", ""], ["", "reached-bottom", ""]],
      outputs: {
        reachedBottom: "reachedBottom"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollbarReachedBottom, [{
    type: Directive,
    args: [{
      selector: '[reachedBottom], [reached-bottom]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }];
  }, {
    reachedBottom: [{
      type: Output
    }]
  });
})();
class NgScrollbarReachedStart extends HorizontalScrollReached {
  constructor(scrollbar, zone, dir) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
    this.dir = dir;
    /** Stream that emits when scroll has reached the start */
    this.reachedStart = this.reachedEvent;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  /**
   * Check if scroll has reached the start (horizontally)
   * @param offset Scroll offset
   * @param e Scroll event
   */
  reached(offset, e) {
    return ReachedFunctions.reachedStart(offset, e, this.dir.value, this.scrollbar.manager.rtlScrollAxisType);
  }
  static {
    this.ɵfac = function NgScrollbarReachedStart_Factory(t) {
      return new (t || NgScrollbarReachedStart)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.Directionality));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollbarReachedStart,
      selectors: [["", "reachedStart", ""], ["", "reached-start", ""]],
      outputs: {
        reachedStart: "reachedStart"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollbarReachedStart, [{
    type: Directive,
    args: [{
      selector: '[reachedStart], [reached-start]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }, {
      type: i2.Directionality
    }];
  }, {
    reachedStart: [{
      type: Output
    }]
  });
})();
class NgScrollbarReachedEnd extends HorizontalScrollReached {
  constructor(scrollbar, zone, dir) {
    super(scrollbar, zone);
    this.scrollbar = scrollbar;
    this.zone = zone;
    this.dir = dir;
    /** Stream that emits when scroll has reached the end */
    this.reachedEnd = this.reachedEvent;
  }
  ngOnInit() {
    super.ngOnInit();
  }
  /**
   * Check if scroll has reached the end (horizontally)
   * @param offset Scroll offset
   * @param e Scroll event
   */
  reached(offset, e) {
    return ReachedFunctions.reachedEnd(offset, e, this.dir.value, this.scrollbar.manager.rtlScrollAxisType);
  }
  static {
    this.ɵfac = function NgScrollbarReachedEnd_Factory(t) {
      return new (t || NgScrollbarReachedEnd)(i0.ɵɵdirectiveInject(i1.NgScrollbar, 8), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i2.Directionality));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollbarReachedEnd,
      selectors: [["", "reachedEnd", ""], ["", "reached-end", ""]],
      outputs: {
        reachedEnd: "reachedEnd"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollbarReachedEnd, [{
    type: Directive,
    args: [{
      selector: '[reachedEnd], [reached-end]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.NgScrollbar,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i0.NgZone
    }, {
      type: i2.Directionality
    }];
  }, {
    reachedEnd: [{
      type: Output
    }]
  });
})();
class NgScrollbarReachedModule {
  static {
    this.ɵfac = function NgScrollbarReachedModule_Factory(t) {
      return new (t || NgScrollbarReachedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgScrollbarReachedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollbarReachedModule, [{
    type: NgModule,
    args: [{
      imports: [NgScrollbarReachedTop, NgScrollbarReachedBottom, NgScrollbarReachedStart, NgScrollbarReachedEnd],
      exports: [NgScrollbarReachedTop, NgScrollbarReachedBottom, NgScrollbarReachedStart, NgScrollbarReachedEnd]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgScrollbarReachedBottom, NgScrollbarReachedEnd, NgScrollbarReachedModule, NgScrollbarReachedStart, NgScrollbarReachedTop };
